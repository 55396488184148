import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import META from '../components/META'
import Layout from '../components/layout'

import { Sec, Wrap, Grid, Col } from '../components/styled/layout'
import { Title, Heading } from '../components/styled/typography'

class InfoPage extends PureComponent {
  state = {}
  calcTotalRating = (movies, person) => {
    return movies
      .map(movie => movie.node.data[person + '_betyg'])
      .reduce((total, betyg) => total + betyg)
  }
  render() {
    const movies = this.props.data.allAirtable.edges
    const janetTotBetyg = this.calcTotalRating(movies, 'Janets')
    const lisaTotBetyg = this.calcTotalRating(movies, 'Lisas')

    const highestRater = () => {
      if (janetTotBetyg > lisaTotBetyg) {
        return 'Janet'
      } else {
        return 'Lisa - ' + lisaTotBetyg
      }
    }
    return (
      <>
        <META title={'Om oss'} />
        <Layout>
          <Sec>
            <Wrap>
              <Grid cols="2fr 1fr">
                <Col>
                  <Title>Om Biobrudarna</Title>
                  <p>
                    Vi är två glada filmälskare som ville komma på ett sätt att
                    spara alla film-minnen vi haft och förhoppningsvis kommer
                    att få. Denna hemsida är därför enbart gjord för vår egen
                    skull och har ingen tanke om att ha något värde för någon
                    annan. Att gå på bio tycker vi är ett utmärkt sätt att
                    umgås. Vi brukar äta en bit mat och prata om allt mellan
                    himmel och jord innan vi ser den film som vi valt ut.
                  </p>
                </Col>
                <Col>
                  <Heading>Onödig fakta</Heading>
                  <dl>
                    <dt>Antal biobesök:</dt>
                    <dd>
                      🍿 {movies.length} {}
                    </dd>
                    <dt>Har gett högst betyg:</dt>
                    <dd>
                      ⭐ {highestRater()} {}
                    </dd>
                  </dl>
                </Col>
              </Grid>
            </Wrap>
          </Sec>
        </Layout>
      </>
    )
  }
}

export default InfoPage

export const query = graphql`
  query AllMoviesQuery_2 {
    allAirtable(sort: { fields: [data___Datum], order: DESC }) {
      edges {
        node {
          id
          data {
            Path
            Film
            gemBetyg
            Janets_betyg
            Lisas_betyg
            Datum
          }
        }
      }
    }
  }
`
